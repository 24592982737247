import React from 'react';
import {StaticImage} from "gatsby-plugin-image"
import Footer from "../components/footer";
import {Link} from "gatsby";
import {Helmet} from "react-helmet";
import '../styles/index.css';

function Index() {

    return (<main>
        <Helmet>
            <title>Christian Briggs</title>
        </Helmet>

        <section className="bg-main">
            <div className="container">
                <div className="row">
                    <div class="col-sm-12 col-md-8 content-container">

                        <h1>Christian Briggs</h1>
                        <h2 class="lead">Senior Digital Product Designer</h2>
                        <p className="lead">I create digital products with amazing teams.</p>

                        <p>
                            <br/>Please download my resume <a
                            href="/christian-briggs-resume-public.pdf" target="_blank">here</a> or
                            contact me on <a href="https://www.linkedin.com/in/christianbriggs/">LinkedIn.</a>
                            <br/>Please also check out my user research-focused experience <Link to="/user-research">here.
                        </Link>
                        </p>

                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-10 content-container">
                        <h2>2022-Present: Senior Digital Product Designer at hc1</h2>
                        <p>
                            <Link to="https://hc1.com/" target="_blank">hc1</Link> was founded in 2011 to identify real-time insights and risk signals from complex
                            laboratory data. Since then it has added innovative products that help health care providers
                            improve pharmacogenetic patient outcomes and post market clinical follow-up for MedTech
                            companies.
                        </p>
                        <p>
                            As hc1's first Digital Product Designer, I work with our amazing Product,
                            Engineering, Marketing, Sales and Scientific teams to grow a customer-centric, design-first
                            product approach.
                        </p>
                    </div>
                </div>

      {/*          <div className="row">
                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/ixdf-mentor-marketplace">
                                <StaticImage
                                    src="../images/card-thumb-mentor-marketplace.png"
                                    alt="Mentor Marketplace screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />

                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">IxDF Mentor Marketplace</h5>

                            </div>
                            <div className="card-body">

                                <p className="card-text">
                                    Designed and shipped MVP of a self-service platform that enabled 152
                                    mentoring sessions (95% of all bootcamp students) in the first 2 months.
                                </p>
                                <Link to="/ixdf-mentor-marketplace">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/ixdf-referral-system">
                                <StaticImage
                                    src="../images/card-thumb-referral.png"
                                    alt="Referral System screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />
                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">IxDF Referral System</h5>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Redesigned and shipped the new IxDF referral system that enabled Admins to manage
                                    new
                                    types of referrals and gain an average of 50% more referrals in each of the three
                                    months after launch.
                                </p>
                                <Link to="/ixdf-referral-system">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/ixdf-bootcamps-site">
                                <StaticImage
                                    src="../images/card-thumb-bootcamps-site.png"
                                    alt="Journey Mapping course screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />
                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">IxDF Bootcamps Site</h5>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Designed and shipped landing/signup pages for IxDF Bootcamps that attracted $2.8m
                                    gross revenue in the first 6 months.
                                </p>
                                <Link to="/ixdf-bootcamps-site">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/ixdf-journey-mapping-course">
                                <StaticImage
                                    src="../images/card-thumb-journey-mapping.png"
                                    alt="Journey Mapping course screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />
                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">IxDF Journey Mapping Course</h5>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Designed, created and shipped a 6-week course on Journey Mapping that became one of
                                    the
                                    IxDF’s top 3, with over 8k students in the first 6 months.
                                </p>
                                <Link to="/ixdf-journey-mapping-course">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/ixdf-matching-system">
                                <StaticImage
                                    src="../images/card-thumb-matching-system.png"
                                    alt="three device screens with content"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />
                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">IxDF Matching System</h5>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Designed a new way to match learning content and events with student history and
                                    goals.
                                </p>

                                <Link to="/ixdf-matching-system">View case study</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/about-me">
                                <StaticImage
                                    src="../images/card-thumb-about.png"
                                    alt="three device screens with content"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />
                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">About Me</h5>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    I've been creating digital products for 10+ years. Please click mo
                                </p>

                                <Link to="/about-me">Learn more</Link>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>
        </section>

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-10 content-container">
                        <h2>2020-2022: Senior Digital Product Designer at the Interaction Design Foundation
                            (IxDF)</h2>
                        <p>
                            The IxDF was founded in 2002 and serves over 140,000 students. As a Senior Product
                            Designer, I collaborate with all parts of the IxDF to design and ship features of the
                            IxDF platform and products.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/ixdf-mentor-marketplace">
                                <StaticImage
                                    src="../images/card-thumb-mentor-marketplace.png"
                                    alt="Mentor Marketplace screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />

                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">IxDF Mentor Marketplace</h5>

                            </div>
                            <div className="card-body">

                                <p className="card-text">
                                    Designed and shipped MVP of a self-service platform that enabled 152
                                    mentoring sessions (95% of all bootcamp students) in the first 2 months.
                                </p>
                                <Link to="/ixdf-mentor-marketplace">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/ixdf-referral-system">
                                <StaticImage
                                    src="../images/card-thumb-referral.png"
                                    alt="Referral System screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />
                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">IxDF Referral System</h5>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Redesigned and shipped the new IxDF referral system that enabled Admins to manage
                                    new
                                    types of referrals and gain an average of 50% more referrals in each of the three
                                    months after launch.
                                </p>
                                <Link to="/ixdf-referral-system">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/ixdf-bootcamps-site">
                                <StaticImage
                                    src="../images/card-thumb-bootcamps-site.png"
                                    alt="Journey Mapping course screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />
                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">IxDF Bootcamps Site</h5>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Designed and shipped landing/signup pages for IxDF Bootcamps that attracted $2.8m
                                    gross revenue in the first 6 months.
                                </p>
                                <Link to="/ixdf-bootcamps-site">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/ixdf-journey-mapping-course">
                                <StaticImage
                                    src="../images/card-thumb-journey-mapping.png"
                                    alt="Journey Mapping course screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />
                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">IxDF Journey Mapping Course</h5>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Designed, created and shipped a 6-week course on Journey Mapping that became one of
                                    the
                                    IxDF’s top 3, with over 8k students in the first 6 months.
                                </p>
                                <Link to="/ixdf-journey-mapping-course">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/ixdf-matching-system">
                                <StaticImage
                                    src="../images/card-thumb-matching-system.png"
                                    alt="three device screens with content"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />
                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">IxDF Matching System</h5>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Designed a new way to match learning content and events with student history and
                                    goals.
                                </p>

                                <Link to="/ixdf-matching-system">View case study</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">
                            <Link to="/about-me">
                                <StaticImage
                                    src="../images/card-thumb-about.png"
                                    alt="three device screens with content"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />
                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">About Me</h5>

                            </div>
                            <div className="card-body">
                                {/*<p className="card-text">*/}
                                {/*    I've been creating digital products for 10+ years. Please click mo*/}
                                {/*</p>*/}

                                <Link to="/about-me">Learn more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-10 content-container">
                        <h2>2018-2020: UX Designer at RideAmigos and Freelance Developer</h2>
                        <p>
                            By day I work on the design and development of RideAmigos
                            SaaS Transportation Demand System products. Nights and weekends I work on scientific
                            research projects.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">
                            <Link to="/ra-admin-networks-interface">

                                <StaticImage
                                    src="../images/card-thumb-admin-interface.png"
                                    alt="Admin interface screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />

                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">RideAmigos Admin Interface Redesign</h5>
                                <span className="badge bg-tag text-dark">UX Research</span>
                                <span className="badge bg-tag text-dark">UX Design</span>
                                <span className="badge bg-tag text-dark">Interviews</span>
                                <span className="badge bg-tag text-dark">Focus groups</span>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    User research, design, prototype and testing of Admin experience </p>
                                <Link to="/ra-admin-networks-interface">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">
                            <Link to="/ra-mobile-trip-planning">

                                <StaticImage
                                    src="../images/card-thumb-mobile-trip-planning.png"
                                    alt="Mobile Trip Planning phone screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />

                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">RideAmigos Commute Tracker Trip Planning</h5>
                                <span className="badge bg-tag text-dark">UX Research</span>
                                <span className="badge bg-tag text-dark">UX Design</span>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Design, prototype and testing of new mobile trip
                                    planning feature
                                </p>
                                <Link to="/ra-mobile-trip-planning">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">
                            <Link to="/ra-carpool-verification">

                                <StaticImage
                                    src="../images/header-carpool-verification.png"
                                    alt="Carpool Verification screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />

                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">RideAmigos Carpool Verification System</h5>
                                <span className="badge bg-tag text-dark">UX Research</span>
                                <span className="badge bg-tag text-dark">UX Design</span>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    User research, design, prototype and testing of new feature to
                                    verify carpool trips
                                </p>
                                <Link to="/ra-carpool-verification">View case study</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">
                            <Link to="/visual-energy-training-exercise">

                                <StaticImage
                                    src="../images/vete-prototype.png"
                                    alt="VETE prototype screen"
                                    placeholder="blurred"
                                    className="card-img-top"
                                />

                            </Link>
                            <div className="card-header">
                                <h5 className="card-title">Visual Energy Training Exercise</h5>
                                <span className="badge bg-tag text-dark">Designer</span>
                                <span className="badge bg-tag text-dark">Full-Stack Developer</span>
                                <span className="badge bg-tag text-dark">Researcher</span>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    User research, design, full-stack dev for NSF-funded research
                                </p>

                                <Link to="/visual-energy-training-exercise">View case study</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="container">
                <h2>
                    2009 to 2017: Digital Product Designer, PhD Researcher,
                    Book Author
                </h2>
                <p>
                    During this intensely productive time, I secured funding
                    to research, design and build Choir while conduct
                    cutting-edge research and sharing what I learned with
                    the public through a book, invited talks and published
                    research.
                </p>
                <div className="row">
                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">

                            <StaticImage
                                src="../images/choir-sift-interview-videos.png"
                                alt="Screen showing videos of Choir Sift interviews"
                                placeholder="blurred"
                                className="card-img-top"
                            />

                            <div className="card-header">
                                <h5 className="card-title">Choir Product Research and Design</h5>
                                <span className="badge bg-tag text-dark">Co-Founder</span>
                                <span className="badge bg-tag text-dark">Designer</span>
                                <span className="badge bg-tag text-dark">User Researcher</span>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Launched first iteration of a digital learning
                                    platform</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">

                            <StaticImage
                                src="../images/header-iu.png"
                                alt="Indiana University logo"
                                placeholder="blurred"
                                className="card-img-top"
                            />

                            <div className="card-header">
                                <h5 className="card-title">PhD, Human-Computer Interaction</h5>
                                <span className="badge bg-tag text-dark">Researcher</span>
                                <span className="badge bg-tag text-dark">Org Analysis</span>
                                <span className="badge bg-tag text-dark">Teacher</span>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Researched design of large-scale sociotechnical
                                    systems at IU Bloomington</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">

                            <StaticImage
                                src="../images/digital-fluency-book.png"
                                alt="Digital Fluency book"
                                placeholder="blurred"
                                className="card-img-top"
                            />

                            <div className="card-header">
                                <h5 className="card-title">Digital Fluency Book</h5>
                                <span className="badge bg-tag text-dark">Co-Author</span>
                                <span className="badge bg-tag text-dark">Field Research</span>
                                <span className="badge bg-tag text-dark">Writing</span>

                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Researched and co-wrote <a
                                    href="https://www.amazon.com/Digital-Fluency-Building-Success-Age/dp/0615642942"
                                    target="_blank"><i>Digital Fluency: Building Success in the Digital Age</i></a>
                                    , a book about the practical
                                    development of digital skills</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">

                            <StaticImage
                                src="../images/card-thumb-talks.png"
                                alt="Images of Christian speaking"
                                placeholder="blurred"
                                className="card-img-top"
                            />

                            <div className="card-header">
                                <h5 className="card-title">Publications and Invited Talks</h5>
                                <span className="badge bg-tag text-dark">Writer and Speaker</span>
                                <span className="badge bg-tag text-dark">Workshops</span>
                                <span className="badge bg-tag text-dark">Presentations</span>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Throughout this time, i shared as much of my learning as possible with the
                                    public</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div className="container">
                <h2>2005 to 2008: Digital Product Designer, Consultant and
                    Master’s Degree Student</h2>
                <p>
                    After a decade of practical work on digital products, I
                    decided to extend my experience with formal education.
                    During this time I continued to consult and secured
                    angel funding to research and design BigTreeTop.com.</p>
                <div className="row">
                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">

                            <StaticImage
                                src="../images/header-bigtreetop.png"
                                alt="BigTreeTop logo"
                                placeholder="blurred"
                                className="card-img-top"
                            />

                            <div className="card-header">
                                <h5 className="card-title">BigTreeTop.com Product Research and Design</h5>
                                <span className="badge bg-tag text-dark">Co-founder</span>
                                <span className="badge bg-tag text-dark">Designer</span>
                                <span className="badge bg-tag text-dark">User Researcher</span>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Launched first iteration of an angel-funded customer co-creation platform.</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">

                            <StaticImage
                                src="../images/header-socialens.png"
                                alt="SociaLens logo"
                                placeholder="blurred"
                                className="card-img-top"
                            />

                            <div className="card-header">
                                <h5 className="card-title">SociaLens Consulting</h5>
                                <span className="badge bg-tag text-dark">Co-founder</span>
                                <span className="badge bg-tag text-dark">Consulting</span>
                                <span className="badge bg-tag text-dark">Research</span>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Digital skill consulting with clients like YMCA
                                    of the USA, Aerobed, Indiana University.</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-4">
                        <div className="card">

                            <StaticImage
                                src="../images/header-iu.png"
                                alt="Indiana University logo"
                                placeholder="blurred"
                                className="card-img-top"
                            />

                            <div className="card-header">
                                <h5 className="card-title">M.A. Human-Computer Interaction</h5>
                                <span className="badge bg-tag text-dark">Student</span>
                                <span className="badge bg-tag text-dark">User Researcher</span>
                                <span className="badge bg-tag text-dark">UX Design</span>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    Attended the Indiana University School of Informatics to pursue research
                                    interests and formalize my
                                    practical UX skills</p>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <section>
            <div className="container">
                <h2>2000 to 2005: Digital Product Designer, Developer and
                    Instructor</h2>
                <p>
                    From 2000-2005 i worked as a UX designer, developer and educator</p>
                <div className="row">

                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Palladium</h5>

                                <span className="badge bg-tag text-dark">UX Designer</span>
                                <span className="badge bg-tag text-dark">User Researcher</span>

                            </div>
                            <div className="card-body">
                                <p className="card-text">UX research/design of analytics apps for
                                    Wal-Mart,
                                    Biogen and Ahold Global</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Johnson and Wales University</h5>
                                <span className="badge bg-tag text-dark">Instructor</span>
                            </div>
                            <div className="card-body">
                                <p className="card-text">Designed and taught lab-based courses in Web and
                                    Multimedia design and development</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">

                            <div className="card-header">
                                <h5 className="card-title">Houghton Mifflin</h5>
                                <span className="badge bg-tag text-dark">Game designer and developer</span>
                                <span className="badge bg-tag text-dark">UX Design</span>
                                <span className="badge bg-tag text-dark">Game Development</span>
                            </div>
                            <div className="card-body">
                                <p className="card-text">
                                    UX design/development of Flash-based educational
                                    games.</p>

                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-3 mb-4">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">United Communications Group</h5>
                                <span className="badge bg-tag text-dark">Webmaster</span>

                            </div>
                            <div className="card-body">
                                <p className="card-text">Management, UX design and development for trade
                                    publisher</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer/>
    </main>);
}

export default Index;
